<template>
  <div>
    <b-skeleton-wrapper :loading="loading">
      <div class="col-10 offset-1">
        <b-skeleton width="25%" animation="wave" class="mt-4 mb-4"></b-skeleton>

        <b-skeleton-table
          :rows="5"
          :columns="4"
          :table-props="{ bordered: true, striped: true }"
        ></b-skeleton-table>
      </div>
    </b-skeleton-wrapper>
    <app-import-table
      v-if="loading == true"
      :getItems="this.importLine"
      :getRow="this.totalImportLine"
    ></app-import-table>
  </div>
</template>

<script>
import AppImportTable from "../components/AppImportTable.vue";
export default {
  components: { AppImportTable },
  name: "Importazioni",

  data() {
    return {
      importLine: [],
      totalImportLine: 0,
      loading: false,
    };
  },

  created() {
    this.listImportLine(0);
    this.nrImportLine();
  },

  methods: {
    async listImportLine($currentPage) {
      try {
        await this.$store
          .dispatch("contactsImports/getContactsImportsPage", {
            nrCurrentPage: $currentPage,
          })
          .then(() => {
            this.importLine = this.$store.getters[
              "contactsImports/listImports"
            ];
            /* console.log(this.importLine); */
            this.loading = true;
            return this.importLine;
          });
      } catch (e) {
        this.loading = false;
        console.log({ message: "Some error getContactsPage" });
      }
    },

    async nrImportLine() {
      this.totalImportLine = this.$store.getters["contactsImports/nrItem"];
      return this.totalImportLine;
    },
  },
};
</script>
